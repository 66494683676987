import { MdPhotoCamera } from "react-icons/md";
import { MdDesignServices } from "react-icons/md";
import { FaCode } from "react-icons/fa";
import { FaCopyright } from "react-icons/fa";
import ImageRendererIncentive from "./ImageRendererIncentive";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';

const incentives = [
    {
      name: 'Photography',
      src: '/images/PhotographyService.jpg',
      index: 'Photography',
      imageSrc: MdPhotoCamera,
      description: "Memories framed and life's moments captured with our vibrant photography service.",
      iconForeground: 'text-gray-950 dark:text-sky-100',
      iconBackground: 'bg-gray-200 dark:bg-sky-950',
      urlName : 'Image Gallery',
      url : '/portfolio/gallery'
    },
    {
      name: 'Graphic Design',
      src: '/images/DesignService.jpg',
      index: 'Graphic Design',
      imageSrc: MdDesignServices,
      description: "From logos to layouts, we turn concepts into captivating visuals for your brand.",
      iconForeground: 'text-gray-950 dark:text-sky-100',
      iconBackground: 'bg-gray-200 dark:bg-sky-950',
      urlName : 'Visual Identities',
      url : '/portfolio/visualidentities'
    },
    {
      name: 'Website Development',
      src: '/images/DevService.jpg',
      index: 'Website Development',
      imageSrc: FaCode,
      description: "Helping you turn visitors into loyal fans with websites crafted and developed properly.",
      iconForeground: 'text-gray-950 dark:text-sky-100',
      iconBackground: 'bg-gray-200 dark:bg-sky-950',
      urlName : 'Websites',
      url : '/portfolio/websites'
    },
  ]
  
  export default function Incentive() {
    return (
      <div>
        <h1 class="mb-4 text-2xl md:text-3xl lg:text-4xl leading-none text-gray-900 dark:text-gray-100 font-bold">Our Services and Portfolio</h1>
        <div className="bg-gray-50 dark:bg-gray-950">
          <div className="pb-12">
            <div className="bg-gray-100 dark:bg-gray-900 rounded-2xl px-4 pt-8 pb-4">
              <div className="max-w-none grid grid-cols-1 lg:grid-cols-3 gap-y-10 gap-x-4">
                {incentives.map((incentive) => (
                  <div key={incentive.name} className="block text-center">
                    <ImageRendererIncentive image={incentive.src} key={incentive.index}/>
                    <div className="text-center">
                      {incentive.imageSrc}
                    </div>
                    <div className="mt-3 sm:mt-0 lg:mt-3 sm:ml-6 lg:ml-0">
                      <h2 className="text-2xl text-gray-900 dark:text-gray-100 font-bold">{incentive.name}</h2>
                      <p class="text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400">{incentive.description}</p>
                    </div>
                    <Link to={incentive.url} class="my-4 inline-flex items-center px-10 py-2 text-sm lg:text-md font-medium text-center text-gray-100 dark:text-gray-900 bg-gray-800 dark:bg-gray-200 hover:bg-gray-700 dark:hover:bg-gray-300 overflow-hidden transition-all duration-300 rounded-md">
                      {incentive.urlName}
                      <IoIosArrowForward class="rtl:rotate-180 w-3.5 h-3.5 ms-2"/>
                    </Link>
                  </div>
                ))}
              </div>
              <div className="mt-2 flex ">
                <FaCopyright className="h-4 w-4 mr-2 text-gray-500 dark:text-gray-400" aria-hidden="true" /> 
                <p class="text-sm text-center lg:text-md font-normal text-gray-500 dark:text-gray-400">Image Credits: Unsplash</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }