import logo from '../logo.svg';
import NavBar from '../blocks/NavBar';
import Hero from '../blocks/Hero'
import CallToAction from '../blocks/CallToAction'
import Incentive from '../blocks/Incentive'
import Footer from '../blocks/Footer'
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../App.css';
import PortfolioList from '../blocks/PortfolioList';
import NewsLetter from '../blocks/NewsLetter';

function Main() {

  useEffect(() => {
    // Check if a fragment identifier is present in the URL
    const hash = window.location.hash;
    if (hash) {
      // Remove the leading '#' character
      const sectionId = hash.substring(1);
      // Find the corresponding section by ID
      const section = document.getElementById(sectionId);
      if (section) {
        // Scroll to the section
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []); // Run this effect only once on component mount
  
  return (
    <div className="Main">
      <Helmet><title>Winksen</title></Helmet>
      <NavBar/>
      <div className="bg-gray-50 dark:bg-gray-950 pb-10"> 
        <Hero/>
        
        {/* <CallToAction/> */}

        <div class="container mx-auto px-10 pt-5">
          <div className="container mx-auto" id="incentive">
            <Incentive/>
          </div>
          {/* <div className="container mx-auto pt-5" id="portfolio">
            <PortfolioList/>
          </div> */}
          <div className="container mx-auto" id="newsletter">
            <NewsLetter/>
          </div>
          <Footer/>
        </div>
      </div>
    </div>
  );
}

export default Main;
