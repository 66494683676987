import { IoIosArrowForward } from "react-icons/io";
import { useState } from 'react';
import { BiSolidMessageAltError } from "react-icons/bi";
import { FaCircleCheck } from "react-icons/fa6";
import { BiSolidError } from "react-icons/bi";

export default function ContactForm() {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [warningMessage, setWarningMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [contact, setContact] = useState({
    firstname: '',
    lastname: '',
    email: '',
    company: '',
    phone: '',
    description: '',
    source: '',
  });

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');
    setWarningMessage('');

    try {
      // Input validation
      if (!contact.firstname.trim()) {
        throw new Error('Firstname is Required');
      }
      if (!contact.lastname.trim()) {
        throw new Error('Lastname is Required');
      }
      if (!contact.email.trim()) {
        throw new Error('Email is Required');
      }
      if (!/\S+@\S+\.\S+/.test(contact.email)) {
        throw new Error('Invalid Email Format');
      }

      // Prepare contact data
      const contactData = {
        firstname: contact.firstname,
        lastname: contact.lastname,
        email: contact.email,
        company: contact.company,
        phone: contact.phone,
        description: contact.description,
        source: contact.source,
      };

      // Make POST request to API
      const response = await fetch(`${baseUrl}/api/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...contactData }),
      });

      // Handle response
      if (!response.ok) {
        console.log("Response not OK");
        console.log(response.status);
        
        const errorMessage = await response.text(); // Extract error message from response body
        console.log(errorMessage);
        throw new Error('Failed to submit form');
      }
      else {
        console.log("Response OK");
        setSuccessMessage('Form submitted successfully! Thank you for contacting us.');
        setContact({
          firstname: '',
          lastname: '',
          email: '',
          company: '',
          phone: '',
          description: '',
          source: '',
        });
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative bg-gray-100 dark:bg-gray-900 rounded-xl">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover lg:absolute lg:h-full rounded-md"
            src="https://images.unsplash.com/photo-1523966211575-eb4a01e7dd51?q=80&w=2020&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
          />
        </div>
      </div>
      <div className="relative py-8 sm:py-10 lg:py-12 px-4 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
        <div className="lg:pr-8">
          <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-gray-900 dark:text-gray-100">Let's work together</h2>
            <p className="mt-4 text-lg text-gray-500 dark:text-gray-400 sm:mt-3">
              We'd love to hear from you! Send us a message using this form or contact us through the email{' '}
              <a href="mailto:contact@winksen.com" className="text-sky-500 hover:underline">
                contact@winksen.com
              </a>.
            </p>
            <form onSubmit={handleSubmit} className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
              <div>
                <label for="firstname" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  First name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="firstname"
                    id="firstname"
                    value={contact.firstname}
                    onChange={(e) => setContact({ ...contact, firstname: e.target.value })}
                    className="block w-full sm:text-sm bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-gray-100 p-2 rounded-md focus:outline-none focus:ring focus:border-sky-500"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="lastname" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Last name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="lastname"
                    id="lastname"
                    value={contact.lastname}
                    onChange={(e) => setContact({ ...contact, lastname: e.target.value })}
                    className="block w-full sm:text-sm bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-gray-100 p-2 rounded-md focus:outline-none focus:ring focus:border-sky-500"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Email
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={contact.email}
                    onChange={(e) => setContact({ ...contact, email: e.target.value })}
                    className="block w-full sm:text-sm bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-gray-100 p-2 rounded-md focus:outline-none focus:ring focus:border-sky-500"
                  />
                </div>
              </div>
              <div>
              <div className="flex justify-between">
                  <label htmlFor="company" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Company
                  </label>
                  <span id="company-description" className="text-sm text-gray-500">
                    Optional
                  </span>
                </div>
                <div className="mt-1">
                  <input
                    type="text"
                    name="company"
                    id="company"
                    value={contact.company}
                    onChange={(e) => setContact({ ...contact, company: e.target.value })}
                    className="block w-full sm:text-sm bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-gray-100 p-2 rounded-md focus:outline-none focus:ring focus:border-sky-500"
                  />
                </div>
              </div>
              <div>
                <div className="flex justify-between">
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Phone
                  </label>
                  <span id="phone-description" className="text-sm text-gray-500">
                    Optional
                  </span>
                </div>
                <div className="mt-1">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    value={contact.phone}
                    onChange={(e) => setContact({ ...contact, phone: e.target.value })}
                    aria-describedby="phone-description"
                    className="block w-full sm:text-sm bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-gray-100 p-2 rounded-md focus:outline-none focus:ring focus:border-sky-500"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="flex justify-between">
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    How can we help you?
                  </label>
                  <span id="description" className="text-sm text-gray-500">
                    Max. 500 characters
                  </span>
                </div>
                <div className="mt-1">
                  <textarea
                    id="description"
                    name="description"
                    value={contact.description}
                    onChange={(e) => setContact({ ...contact, description: e.target.value })}
                    aria-describedby="description-description"
                    rows={4}
                    className="block w-full sm:text-sm bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-gray-100 p-2 rounded-md focus:outline-none focus:ring focus:border-sky-500"
                    defaultValue={''}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="source" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  How did you hear about us?
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="source"
                    id="source"
                    value={contact.source}
                    onChange={(e) => setContact({ ...contact, source: e.target.value })}
                    className="w-full bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-gray-100 p-2 rounded-md focus:outline-none focus:ring focus:border-sky-500"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <button
                  type="submit"
                  className="w-full sm:max-w-xl mt-4 py-2 text-sm font-medium text-gray-100 bg-sky-600 dark:bg-sky-700 hover:bg-sky-500 dark:hover:bg-sky-600 overflow-hidden transition-all duration-300 rounded-md"
                  disabled={loading}
                >
                  {loading ? (
                    <span className="flex items-center justify-center">
                      Submitting
                      <svg aria-hidden="true" class="inline w-3.5 h-3.5 ms-2 animate-spin text-sky-800 fill-gray-200" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                      </svg>
                    </span>
                  ) : (
                    <span className="flex items-center justify-center">
                      Submit<IoIosArrowForward className="rtl:rotate-180 w-3.5 h-3.5 ms-2" />
                    </span>
                  )}
                </button>
              </div>
              <div className="sm:col-span-2">
                {successMessage && !loading &&
                  <div className='flex items-center justify-center mt-2'>
                    <FaCircleCheck className="text-green-600 dark:text-green-500 h-5 w-5 mr-1"/><p className="text-green-600 dark:text-green-500">{successMessage}</p>
                  </div>
                }
                {warningMessage && !loading &&
                  <div className='flex items-center justify-center mt-2'>
                    <BiSolidMessageAltError className="text-yellow-600 dark:text-yellow-500 h-5 w-5 mr-1"/><p className="text-yellow-600 dark:text-yellow-500">{warningMessage}</p>
                  </div>
                }
                {errorMessage && !loading &&
                  <div className='flex items-center justify-center mt-2'>
                    <BiSolidError className="text-red-600 dark:text-red-500 h-5 w-5 mr-1"/><p className="text-red-600 dark:text-red-500">{errorMessage}</p>
                  </div>
                }
              </div>  
            </form>
            {/* <p className="mt-4 text-lg text-gray-500 dark:text-gray-400 sm:mt-3">
              Image Credits: <strong>Unsplash</strong>
            </p> */}
          </div>
        </div>
      </div>
    </div>
  )
}