import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CameraIcon, DevicePhoneMobileIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";

export default function Modal({ isOpen, onClose, imageDetails }) {
  const { id, gallery_id, imageUrl, title, imageType, gallery, camera, cameraType, formatted_date, dimensions, software } = imageDetails || {};

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={onClose}>
        <Transition.Child as={Fragment} enter="ease-in-out duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-100 dark:bg-gray-900 bg-opacity-80 dark:bg-opacity-80 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 flex items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="ease-in duration-200"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0"
          >
            <Dialog.Panel className="relative bg-gray-100 dark:bg-gray-900 p-6 rounded-xl shadow-xl max-w-lg w-full border-2 border-sky-400 dark:border-sky-700">
              <div className="space-y-4">
                <div>
                  <div className="aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg">
                    <img
                      src={imageUrl}
                      alt={title}
                      className="object-cover"
                    />
                  </div>
                  <div className="mt-4">
                    <h2 className="text-lg font-medium text-gray-900 dark:text-gray-100">{title || '-'}</h2>
                    <p className="text-sm font-medium text-gray-400 mt-2">Image Type: <span className="bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-gray-300 px-2 py-0.5 rounded">{imageType || '-'}</span></p>
                  </div>
                </div>
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-gray-100">Image Information</h3>
                  <dl className="mt-2 divide-y divide-gray-200 dark:divide-gray-800 border-t border-b border-gray-200 dark:border-gray-800">
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-400 py-1">Gallery</dt>
                      <dd className="text-gray-900 dark:text-gray-100 py-1"><span class="bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-gray-300 text-sm font-medium px-4 py-2 rounded-md">{gallery !== null ? gallery : '-'}</span></dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-400">Camera</dt>
                      <dd className="text-gray-900 dark:text-gray-100">{camera !== null ? camera : '-'}</dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-400">Capture Date</dt>
                      <dd className="text-gray-900 dark:text-gray-100">{formatted_date !== null ? formatted_date : '-'}</dd>
                    </div>
                  </dl>
                </div>
                <div className="flex justify-start">
                  <Link to={`/portfolio/gallery/${gallery_id}/image/${id}`} className="inline-flex items-center px-4 py-2 text-sm font-medium bg-gray-200 border-gray-200 dark:bg-gray-800 dark:border-gray-800 text-gray-900 dark:text-gray-100 rounded-md hover:bg-gray-300 dark:hover:bg-gray-700">
                    View More Details
                    <IoIosArrowForward className="w-4 h-4 ml-2" />
                  </Link>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
