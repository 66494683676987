import { PhotoIcon, PaintBrushIcon, GlobeAltIcon, CursorArrowRaysIcon } from '@heroicons/react/20/solid'
import { Helmet } from 'react-helmet';
import NavBar from '../blocks/NavBar';
import BreadCrumb from '../blocks/BreadCrumb';
import DividerDown from '../blocks/DividerDown';
import DividerDot from '../blocks/DividerDot';
import Footer from '../blocks/Footer';


const pages = [
  { name: 'Coming Soon!', href: '/coming-soon', current: true },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ComingSoon() {
  return (
      <div className="ImageDetails">

        <Helmet><title>Winksen // Coming Soon</title></Helmet>
        <NavBar/>

        <body className="bg-gray-50 dark:bg-gray-950 pb-10">
          <div class="container mx-auto px-10 pt-5">
            {/* BreadCrumb */}
            <BreadCrumb pages={pages}/>
            <DividerDot/>
            <br />

            <div class="text-center bg-gray-100 dark:bg-gray-900 py-24 rounded-xl mb-2">
              <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-gray-100 md:text-5xl lg:text-6xl">This Page is <span class="bg-gradient-to-r from-sky-600 to-sky-400 text-transparent bg-clip-text">Coming SOON!</span></h1>
              <p class="text-lg font-normal lg:text-xl text-gray-500 dark:text-gray-400">Stay Tuned!</p>
            </div>

            <Footer/>
          </div>
        </body>
      </div>
  )
}
  