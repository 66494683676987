import React, { useState, useEffect } from 'react';
import { MdDarkMode, MdLightMode } from "react-icons/md";

const ToggleDarkMode = () => {
  // const [darkMode, setDarkMode] = useState(false);
  const [darkMode, setDarkMode] = useState(() => {
		const initialTheme = localStorage.getItem("darkMode");
    console.log(initialTheme);
		return initialTheme;
	});

  useEffect(() => {
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    console.log("Dark Mode is");
    console.log(isDarkMode);
    console.log(darkMode);
    setDarkMode(isDarkMode);
    console.log(darkMode);
  }, []);

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
    localStorage.setItem('darkMode', darkMode);
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    console.log("Dark Mode After is");
    console.log(isDarkMode);
    console.log(darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <div>
      <button onClick={toggleDarkMode} className="inline-flex items-center px-5 py-1 rounded-md text-gray-900 dark:text-gray-100 bg-gray-200 dark:bg-sky-950 text-base font-medium">
      {darkMode ? <MdDarkMode className="mr-2" /> : <MdLightMode className="mr-2" />} Theme 
      </button>
      {/* <h1 className='text-gray-900 dark:text-gray-300'>Current theme: {darkMode ? 'Dark Mode' : 'Light Mode'}</h1> */}
    </div>
  );
};

export default ToggleDarkMode;