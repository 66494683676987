import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom';
import {
  Bars3Icon,
  PencilSquareIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import ToggleDarkMode from './ToggleDarkMode'
import { BiSolidFolderOpen } from "react-icons/bi";
import { MdTextSnippet } from "react-icons/md";
import { IoMdMore } from "react-icons/io";
import { FaHistory } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";
import { BiSolidMessageDots } from "react-icons/bi";
import { PhotoIcon, PaintBrushIcon, GlobeAltIcon, CursorArrowRaysIcon } from '@heroicons/react/20/solid';
import { HiPaintBrush } from "react-icons/hi2";

const portfolio = [
  {
    name: 'Image Gallery',
    description: "Capturing beauty in every moment.",
    href: '/portfolio/gallery',
    icon: PhotoIcon,
  },
  {
    name: 'Visual Identities',
    description: "Logos that tell your brand's story.",
    href: '/portfolio/visualidentities',
    icon: HiPaintBrush,
  },
  {
    name: 'Websites',
    description: "Where design meets seamless interactivity.",
    href: '/portfolio/websites',
    icon: GlobeAltIcon,
  },
]
const resources = [
  { name: 'Help Center (Soon)', description: 'Get all of your questions answered in our forums or contact support.', href: '/help', icon: <FaQuestionCircle className='h-5 w-5 text-gray-950 dark:text-sky-100'/> },
  { name: 'Content ChangeLog', description: 'Content ChangeLog', href: '/changelog-contents', icon: <FaHistory className='h-5 w-5 text-gray-950 dark:text-sky-100'/> },
  { name: 'Application ChangeLog', description: 'Application ChangeLog', href: '/changelog', icon: <FaHistory className='h-5 w-5 text-gray-950 dark:text-sky-100'/> },
  // { name: 'Contact', description: 'Learn how to maximize our platform to get the most out of it.', href: '/contact', icon: <BiSolidMessageDots className='h-5 w-5 text-gray-950 dark:text-sky-100'/> },
  // { name: 'Events', description: 'See what meet-ups and other events we might be planning near you.', href: '#' },
  // { name: 'Security', description: 'Understand how we take your privacy seriously.', href: '#' },
]

export default function NavBar() {
  return (
    <Popover className="z-20 bg-gray-100 dark:bg-gray-900 border-b-2 border-gray-300 dark:border-gray-700 sticky top-0">
      <div className="flex items-center justify-between px-4 py-2 sm:px-6 md:justify-start md:space-x-10">
        <div>
          <Link to="/" className="flex">
            <span className="sr-only">Winksen</span>
            {/* {darkMode ? <span>Hello</span> : <span>Hello2</span>} */}
            <img className="h-12 w-auto sm:h-14" src="/logo/Logo192.png" alt="Winksen Logo"/>
          </Link>
        </div>
        <div className="">
          <ToggleDarkMode />
        </div>
        <div className="-my-2 -mr-2 md:hidden">
          <Popover.Button className="inline-flex items-center justify-center rounded-md bg-gray-100 dark:bg-gray-900 p-2 text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-500">
            <span className="sr-only">Open menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>

        <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
          <Popover.Group as="nav" className="flex space-x-10">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button className='group focus:outline-none inline-flex items-center rounded-md bg-gray-200 dark:bg-sky-950 px-5 py-1 text-base font-medium text-gray-600 dark:text-gray-100'>
                    <span className='inline-flex items-center'><BiSolidFolderOpen className='mr-2'/> Portfolio</span>
                    <ChevronDownIcon className='ml-2 h-5 w-5' aria-hidden="true" />
                  </Popover.Button>

                  <Transition as={Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0 translate-y-1" enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 translate-y-1">
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform lg:max-w-3xl">
                      <div className="overflow-hidden rounded-lg ring-2 ring-gray-300 dark:ring-gray-700 ring-opacity-75">
                        <div className="relative grid gap-6 bg-gray-50 dark:bg-gray-950 px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-3">
                          {portfolio.map((item) => (
                            <Link to={item.href} key={item.name} className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100 dark:hover:bg-gray-900">
                              <div className="bg-gray-200 dark:bg-sky-950 px-6 py-4 rounded-md mr-2">
                                <item.icon className="h-6 w-6 text-gray-950 dark:text-sky-100" aria-hidden="true" />
                              </div>
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900 dark:text-gray-100">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </Link>
                          ))}
                        </div>
                        {/* <div className="bg-gray-100 dark:bg-gray-900 p-5 sm:p-8">
                          <Link to="/" className="-m-3 flow-root rounded-md p-3 hover:bg-gray-200 dark:hover:bg-gray-800">
                            <div className="flex items-center">
                              <div className="text-base font-medium text-gray-900 dark:text-gray-100">Enterprise</div>
                              <span className="ml-3 inline-flex items-center rounded-full bg-sky-100 px-3 py-0.5 text-xs font-medium leading-5 text-sky-800">
                                New
                              </span>
                            </div>
                            <p className="text-left mt-1 text-sm text-gray-500">
                              Empower your entire team with even more advanced tools.
                            </p>
                          </Link>
                        </div> */}
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <Link to="/blog" className="inline-flex items-center text-base py-1 font-medium text-gray-700 dark:text-gray-300">
              <MdTextSnippet className='mr-2'/> Blog
            </Link>

            <Link to="/contact" className="inline-flex items-center text-base py-1 font-medium text-gray-700 dark:text-gray-300">
              <BiSolidMessageDots className='mr-2'/> Contact
            </Link>

            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button className='group focus:outline-none inline-flex items-center rounded-md bg-gray-200 dark:bg-sky-950 px-5 py-1 text-base font-medium text-gray-600 dark:text-gray-100'>
                    <span className='inline-flex items-center'><IoMdMore className='mr-2'/>More</span>
                    <ChevronDownIcon className='ml-2 h-5 w-5 text-gray-600 dark:text-gray-100 ' aria-hidden="true" />
                  </Popover.Button>

                  <Transition as={Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0 translate-y-1" enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 translate-y-1">
                    <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-2 ring-gray-300 dark:ring-gray-700 ring-opacity-75">
                        <div className="relative grid gap-6 bg-gray-50 dark:bg-gray-950 px-5 py-6 sm:gap-8 sm:p-8">
                          {resources.map((item) => (
                            <Link key={item.name} to={item.href} className="-m-3 rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-900 inline-flex items-center">
                              <div className='bg-gray-200 dark:bg-sky-950 px-6 py-4 rounded-md mr-2'>
                                {item.icon}
                              </div>
                              <div>
                                <p className="text-base font-medium text-gray-900 dark:text-gray-100">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>
          {/* <div className="flex items-center md:ml-12">
            <Link to="/" className="transition ease-in delay-150 text-base font-medium text-gray-700 dark:text-gray-300 hover:text-sky-500 dark:hover:text-sky-500">
              Sign in
            </Link>
            <Link to="/" className="transition ease-in delay-150 ml-8 inline-flex items-center justify-center rounded-md border border-transparent bg-sky-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sky-600">
              Sign up
            </Link>
          </div> */}
        </div>
      </div>

      <Transition as={Fragment} enter="duration-200 ease-out" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="duration-100 ease-in" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
        <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
          <div className="divide-y-2 divide-gray-300 dark:divide-gray-700 rounded-lg bg-gray-50 dark:bg-gray-950 shadow-lg ring-2 ring-gray-300 dark:ring-gray-700 ring-opacity-75">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="/logo192.png"
                    alt="Your Company"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-gray-100 dark:bg-gray-900 p-2 text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-6">
                  {portfolio.map((item) => (
                    <Link key={item.name} to={item.href} className="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-100 dark:hover:bg-gray-900">
                      <div className="bg-gray-200 dark:bg-sky-950 px-6 py-4 rounded-md mr-2">
                        <item.icon className="h-6 w-6 text-gray-950 dark:text-sky-100" aria-hidden="true" />
                      </div>
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900 dark:text-gray-100">{item.name}</p>
                        <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                      </div>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5">
              <div className="grid grid-cols-2 gap-4">
                <Link to="/blog" className="inline-flex items-center text-base font-medium text-gray-700 dark:text-gray-300 hover:text-sky-500 dark:hover:text-sky-500">
                  <MdTextSnippet className='mr-2'/> Blog
                </Link>

                <Link to="/contact" className="inline-flex items-center text-base font-medium text-gray-700 dark:text-gray-300 hover:text-sky-500 dark:hover:text-sky-500">
                  <BiSolidMessageDots className='mr-2'/> Contact
                </Link>
              </div>
            </div>
            <div className="py-6 px-5">
              <div className="grid gap-6">

                {/* <Link to="/" className="text-base font-medium text-gray-700 dark:text-gray-300 hover:text-sky-500 dark:hover:text-sky-500">
                  Docs
                </Link>

                <Link to="/" className="text-base font-medium text-gray-700 dark:text-gray-300 hover:text-sky-500 dark:hover:text-sky-500">
                  Enterprise
                </Link> */}
                {resources.map((item) => (
                  <Link key={item.name} to={item.href} className="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-100 dark:hover:bg-gray-900">
                  <div className='bg-gray-200 dark:bg-sky-950 px-6 py-4 rounded-md mr-2'>
                    {item.icon}
                  </div>
                  <div>
                    <p className="text-base font-medium text-gray-900 dark:text-gray-100">{item.name}</p>
                    <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                  </div>
                </Link>
                ))}
              </div>
              {/* <div className="mt-6">
                <Link to="/" className="flex w-full items-center justify-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sky-700">
                  Sign up
                </Link>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Existing customer?{' '}
                  <Link to="/" className="text-sky-600 hover:text-sky-500">
                    Sign in
                  </Link>
                </p>
              </div> */}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}